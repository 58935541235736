<template>
  <div class="d-flex flex-column flex-shrink-0 p-3 text-bg-dark" style="width: 240px;">

    <!----------------------------->

    <!-- Sidebar - Top -->
    <ul class="nav nav-pills flex-column mb-auto">

      <!-- Profile -->
      <li class="nav-item">
        <router-link class="nav-link text-white" to="/profile" :exact="false">
          <i class="icon bi-person-circle pe-none me-2"></i>
          Profile
        </router-link>
      </li>

      <!-- Tools -->
      <!--
      <li class="nav-item">
        <router-link class="nav-link text-white" to="/tools" :exact="false">
          <i class="icon bi-tools pe-none me-2"></i>
          Tools
        </router-link>
      </li>
      -->

      <!-- Keys -->
      <li class="nav-item">
        <router-link class="nav-link text-white" to="/keys" :exact="false">
          <i class="icon bi-key pe-none me-2"></i>
          Keys
        </router-link>
      </li>

      <!----------------------------->
      <li class="li-disabled"><hr></li>

      <!-- Docs -->
      <li class="nav-item">
        <!--<a href="https://docs.quilt.gg/" class="nav-link text-opacity-50 disabled">-->
        <a href="https://docs.quilt.gg/" class="nav-link text-white">
          <i class="icon bi-book pe-none me-2"></i>
          Docs
        </a>
      </li>

    </ul>


    <!-- Sidebar - Bottom -->
    <ul class="nav nav-pills flex-column">

      <!-- VPN -->
      <li class="nav-item li-disabled">
        <a href="#" class="nav-link text-opacity-50 disabled">
          <i class="icon bi-door-open pe-none me-2"></i>
          VPN
        </a>
      </li>

      <!-- Feedback -->
      <li class="nav-item li-disabled">
        <a href="#" class="nav-link text-opacity-50 disabled">
          <i class="icon bi-chat-text pe-none me-2"></i>
          Feedback
        </a>
      </li>

      <!----------------------------->
      <li class="li-disabled"><hr></li>

      <!-- Active User -->
      <li class="nav-item li-disabled">

        <!-- Clickable Region -->
        <a href="#" class="d-flex align-items-center text-white text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">

          <!-- Profile Picture -->
          <img :src="userImgUrl" alt="" width="32" height="32" class="rounded-circle me-2">

          <!-- User Name -->
          <strong>{{ userName }}</strong>

        </a>

        <!-- User Controls -->
        <ul class="dropdown-menu dropdown-menu-dark text-small shadow">

          <!-- TODO: enable when user has certain roles... -->

          <!-- New Repo... -->
          <li class="nav-item li-disabled"><a class="dropdown-item opacity-50 disabled" href="#">New Repo...</a></li>

          <!-- New Deploy... -->
          <li class="nav-item li-disabled"><a class="dropdown-item opacity-50 disabled" href="#">New Deploy...</a></li>

          <!-- VPN -->
          <!--
          <li class="nav-item li-disabled"><a class="dropdown-item opacity-50 disabled" href="#">VPN</a></li>
          -->

          <!----------------------------->
          <li><hr></li>

          <!-- Logout -->
          <li><a class="dropdown-item" role="button" @click="requestLogout">Logout</a></li>

        </ul>

      </li>

    </ul>

    <!-- Version Info -->
    <div class="mt-n1">
      {{ version }}
    </div>

  </div>
</template>

<script>
import api from "@/api"

export default {
  computed: {
    userImgUrl() {
      return JSON.parse(window.localStorage.getItem("user")).picture || "images/user-circle-solid-36.png"
    },
    userName() {
      return JSON.parse(window.localStorage.getItem("user")).name || "{username}"
    },
    version() {
      return "version 0.0." + (process.env.VUE_APP_BUILD_VERSION || "0")
    }
  },
  methods: {
    async requestLogout() {
      await api.logout()
      //this.$router.push('/login')
    }
  },
};
</script>

<style>

li:hover {
  background-color: rgba(0.25, 0.25, 0.25, 0.25);
  border-radius: var(--bs-nav-pills-border-radius);
}

.li-disabled:hover {
  background-color: transparent;
}

.mt-n1 {
  margin-bottom: -1.0rem!important;
  color: gray;
  align-self: end;
  font-size: x-small;
  font-style: italic;
  font-family: sans-serif;
}

</style>