import axios from "axios"
import store from "@/store";
import router from "@/router";
import {googleLogout} from "vue3-google-login";
//import mqtt from "mqtt";
import config from "@/config"


const instance = axios.create({
  baseURL: config.apiUrl,
  timeout: 5000,
  withCredentials: true,
})


console.log(`root: ${config.apiUrl}`)


// (example)
// install a request interceptor that adds our authorization token
// to outbound requests
//
//instance.interceptors.request.use(function (config) {
//  return new Promise(async (resolve, reject) => {
//    try {
//      resolve(config)
//    } catch (error) {
//      reject(error)
//    }
//  })
//})


// install a response interceptor that will handle re-authorization flows
//
instance.interceptors.response.use(undefined, async function(error) {
  return new Promise(async (resolve, reject) => {
    try {
      if (error && error.response) {
        if (error.response.status === 401) {
          await logout()
        }
        resolve(error.response)
      }
    } catch (error) {
      reject(error)
    }
  })
});


const client = instance

const logout = function() {
  return new Promise(async (resolve, reject) => {
    try {
      googleLogout()
      window.localStorage.removeItem('user')
      await instance({method: "POST", url: "/api/auth/logout"})
      await router.push("/login")
      resolve()
    } catch (error) {
      reject(error)
    }
  })
}


//const mqttConfig = {
//  protocol: "wss",
//  host: process.env.MQTT_HOST || "mqtt.proto.quilt.gg",
//  port: parseInt(process.env.MQTT_PORT || 443),
//  username: process.env.MQTT_USERNAME || "mqttuser",
//  password: process.env.MQTT_PASSWORD || "mqttpassword",
//  reconnectPeriod: 1000,
//};

//const mqttClient = mqtt.connect(
//  `${mqttConfig.protocol}://${mqttConfig.host}:${mqttConfig.port}/mqtt`,
//  {
//    ...mqttConfig,
//    clientId: `TEST_quilt_client_${Math.random().toString(16).substring(2, 10)}`, // Random client ID
//    connectTimeout: mqttConfig.reconnectPeriod,
//  },
//);

export default {
  client,
  logout,
  login: function(provider, creds) {
    return new Promise(async (resolve, reject) => {
      try {

        //mqttClient.publish("test/event", "from web")

        // calling this will result in "access_token" cookie being populated (if user is valid)
        await instance({
          method: "POST",
          url: "/api/auth/v1/oauth/" + provider,
          data: creds
        })

        let me_response = await instance({
          method: "GET",
          url: "/api/auth/v1/identity/me",
        })

        window.localStorage.setItem('user', JSON.stringify(me_response.data))

        resolve(me_response.data)

      } catch (error) {
        reject(error)
      }
    })
  },
  checkAuthStatus: function() {
    return new Promise(async (resolve, reject) => {
      try {
        let response = await instance({
          method: "GET",
          url: "/api/auth/check",
        })
        // todo: when 400/401, do we make it here?
        resolve(response.data)
      } catch (error) {
        // todo: do something here?
        reject(error)
      }
    })
  },
  getAccessToken: function() {
    return new Promise(async (resolve, reject) => {
      try {
        let token_response = await instance({
          method: "GET",
          url: "/api/auth/v1/oauth/token"
        })

        resolve(token_response.data)

      } catch (error) {
        reject(error)
      }
    })
  },
  getStorageManifest: function(path) {
    return new Promise(async (resolve, reject) => {
      try {
        let storage_response = await instance({
          method: "GET",
          url: "/api/storage/v1/" + path
        })

        let manifest = {"count": 0, "files:": []}

        if (storage_response) {
          manifest = storage_response.data
        }

        resolve(manifest)

      } catch (error) {
        reject(error)
      }
    })
  },
  createApiKey: function(name, lifetime) {
    return new Promise(async (resolve, reject) => {
      try {
        let auth_response = await instance({
          method: "POST",
          url: "/api/auth/v1/keys/",
          data: {
            name: name,
            lifetime: lifetime
          }
        })

        let key_info = auth_response.data
        resolve(key_info)

      } catch (error) {
        reject(error)
      }
    })
  },
}